<template>
  <div>
    <navbar-component />
    <main>
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center"
        style="min-height: 75vh"
      >
        <div id='heeruid'
          class="absolute top-0 w-full h-full bg-center bg-cover"
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-12">
                <h1
                  class="text-white font-semibold text-5xl"
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                >
                Welcome to Soft Solutions! 
                </h1>
                <p
                  class="mt-4 text-lg text-gray-300"
                  data-aos="zoom-out-up"
                  data-aos-duration="1500"
                >
                "Your one-stop destination for AI solutions, web development, and app development services. Let's innovate together!"
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
          style="height: 70px"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-gray-300 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      <section class="pb-20 bg-gray-300 -mt-24">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div
              class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center"
              data-aos="fade-down"
              data-aos-duration="1500"
            >
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-teal-600 "
                  >
                    <!-- <i class="fas fa-brain"></i> -->
                    <img alt="..."
                  src="../assets/icons/ml3.png"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  >
                  </div>
                  <h6 class="text-xl font-semibold">Machine Learning</h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    Harnessing the power of data, we specialize in machine learning solutions that unlock insights and drive intelligent decision-making.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="w-full md:w-4/12 px-4 text-center"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-gray-700"
                  >
                    <i class="fas fa-retweet"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Web Development</h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    Crafting engaging and responsive websites, we bring ideas to life with innovative design and seamless functionality.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="pt-6 w-full md:w-4/12 px-4 text-center"
              data-aos="fade-down"
              data-aos-duration="1500"
            >
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-amber-600"
                  >
                    <i class="fas fa-fingerprint"></i>
                  </div>
                  <h6 class="text-xl font-semibold">App Development</h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    From concept to launch, our expert team delivers cutting-edge mobile applications that elevate user experiences and drive business growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="work" class="flex flex-wrap items-center mt-32">
            <div
              class="w-full md:w-5/12 px-4 mr-auto ml-auto"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div
                class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100"
              >
                <!-- <i class="fas fa-user-friends text-xl"></i> -->
                <img alt="..."
                  src="../assets/icons/work.png"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  >
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                Working with us is a pleasure
              </h3>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700"
              >
                We offer a gateway to unlocking the full potential of your
                digital endeavors. Specializing in AI, machine learning, web
                development, and app development services, we bring innovation
                and expertise to every project. With a focus on seamless
                collaboration and tailored solutions, we strive to exceed
                expectations and deliver results that propel your business
                forward. Whether you're venturing into the realm of artificial
                intelligence or seeking to enhance your online presence, our
                dedicated team is here to guide you at every step. Partner with
                us and embark on a journey where creativity meets cutting-edge
                technology, making your vision a reality."
              </p>
              <!-- <a
                href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/presentation"
                class="font-bold text-gray-800 mt-8"
                >Check Tailwind Starter Kit!</a
              > -->
            </div>
            <div
              class="w-full md:w-4/12 px-4 mr-auto ml-auto"
              data-aos="fade-down"
              data-aos-duration="1500"
            >
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-pink-600"
              >
                <img
                  alt="..."
                  src="../assets/img/work.png"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block"
                    style="height: 95px; top: -94px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-pink-600 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    Top Notch Services
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    Our top-notch services encompass a spectrum of unparalleled
                    expertise, delivering excellence in AI and machine learning
                    solutions, web development, and app development.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>

          <div id="services" class="flex flex-wrap items-center mt-32">
            <div
              class="w-full md:w-4/12 px-4 mr-auto ml-auto"
              data-aos="fade-down"
              data-aos-duration="1500"
            >
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-teal-600"
              >
                <img
                  alt="..."
                  src="../assets/img/ai.png"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block"
                    style="height: 95px; top: -94px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-teal-600 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    Top Notch Services
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    Our top-notch services encompass a spectrum of unparalleled
                    expertise, delivering excellence in AI and machine learning
                    solutions, web development, and app development.
                  </p>
                </blockquote>
              </div>
            </div>
            <div
              class="w-full md:w-5/12 px-4 mr-auto ml-auto"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div
                class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100"
              >
                <!-- <i class="fas fa-user-friends text-xl"></i> -->
                <img alt="..."
                  src="../assets/icons/ml2.png"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  >
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                AI and Machine Learning
              </h3>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700"
              >
                At Soft Solutions, we specialize in innovative machine-based
                solutions, leveraging advanced technologies such as machine
                learning (ML), deep learning (DL), natural language processing
                (NLP), computer vision, generative AI, chatbot development, LLM,
                multimodel and diffusion solutions. Our team of experts
                harnesses the power of these technologies to create tailored
                solutions that drive business growth and innovation. Whether you
                need predictive analytics, image recognition, language
                translation, chatbots, or advanced NLP applications, we deliver
                cutting-edge solutions to meet your specific needs. From concept
                to deployment, we are committed to delivering high-quality,
                scalable solutions that help you stay ahead in today&#39;s
                competitive landscape.
              </p>
              <!-- <a
                href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/presentation"
                class="font-bold text-gray-800 mt-8"
                >Check Tailwind Starter Kit!</a
              > -->
            </div>
          </div>

          <div class="flex flex-wrap items-center mt-32">
            <div
              class="w-full md:w-5/12 px-4 mr-auto ml-auto"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div
                class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                Web Development
              </h3>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700"
              >
                At Soft Solutions, we excel in crafting exceptional web
                solutions using cutting- edge technologies such as Laravel,
                Node.js, and React. Our team of skilled developers ensures
                seamless functionality, stunning design, and optimal user
                experience for your website. Whether you need a simple
                informational site or a complex e-commerce platform, we deliver
                tailor-made solutions to help you achieve your business goals.
              </p>
              <!-- <a
                href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/presentation"
                class="font-bold text-gray-800 mt-8"
                >Check Tailwind Starter Kit!</a
              > -->
            </div>
            <div
              class="w-full md:w-4/12 px-4 mr-auto ml-auto"
              data-aos="fade-down"
              data-aos-duration="1500"
            >
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-700"
              >
                <img
                  alt="..."
                  src="../assets/img/web.png"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block"
                    style="height: 95px; top: -94px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-gray-700 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    Top Notch Services
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    Our top-notch services encompass a spectrum of unparalleled
                    expertise, delivering excellence in AI and machine learning
                    solutions, web development, and app development.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap items-center mt-32">
            <div
              class="w-full md:w-4/12 px-4 mr-auto ml-auto"
              data-aos="fade-down"
              data-aos-duration="1500"
            >
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-amber-600"
              >
                <img
                  alt="..."
                  src="../assets/img/app.png"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block"
                    style="height: 95px; top: -94px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-amber-600 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    Top Notch Services
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    Our top-notch services encompass a spectrum of unparalleled
                    expertise, delivering excellence in AI and machine learning
                    solutions, web development, and app development.
                  </p>
                </blockquote>
              </div>
            </div>
            <div
              class="w-full md:w-5/12 px-4 mr-auto ml-auto"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div
                class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                App Development
              </h3>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700"
              >
                Harnessing the power of Flutter, we create dynamic and intuitive
                mobile applications that resonate with your target audience. Our
                experienced team of developers designs and develops custom apps
                for Android and iOS platforms, ensuring smooth performance,
                engaging user interface, and robust functionality. From concept
                to deployment, we work closely with you to bring your app idea
                to life, delivering solutions that exceed your expectations.
              </p>
              <!-- <a
                href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/presentation"
                class="font-bold text-gray-800 mt-8"
                >Check Tailwind Starter Kit!</a
              > -->
            </div>
          </div>
        </div>
      </section>
      <section class="relative py-20">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style="height: 80px"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div
              class="w-full md:w-4/12 ml-auto mr-auto px-4"
              data-aos="fade-down"
              data-aos-duration="1500"
            >
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
                src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=634&amp;q=80"
              />
            </div>
            <div
              class="w-full md:w-5/12 ml-auto mr-auto px-4"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div class="md:pr-12">
                <div
                  class="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300"
                >
                  <!-- <i class="fas fa-rocket text-xl"></i> -->
                  <img alt="..."
                  src="../assets/icons/grow.png"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  >
                </div>
                <h3 class="text-3xl font-semibold">A growing company</h3>
                <p class="mt-4 text-lg leading-relaxed text-gray-600">
                  As a growing company, we're dedicated to innovation, excellence, and exceeding expectations. Join us on our journey as we strive for continuous growth and success in delivering exceptional services to our valued clients."
                </p>
                <ul class="list-none mt-6">
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"
                          ><i class="fas fa-fingerprint"></i
                        ></span>
                      </div>
                      <div>
                        <h4 class="text-gray-600">
                          Innovative solutions
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"
                          ><i class="fab fa-html5"></i
                        ></span>
                      </div>
                      <div>
                        <h4 class="text-gray-600">Cutting-edge technology tailored to your needs </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"
                          ><i class="far fa-paper-plane"></i
                        ></span>
                      </div>
                      <div>
                        <h4 class="text-gray-600">Elevated experiences delivered seamlessly </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="aboutUs" class="pt-20 pb-48">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center text-center mb-24">
            <div class="w-full lg:w-6/12 px-4">
              <h2
                class="text-4xl font-semibold"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                Here are our heroes
              </h2>
              <p
                class="text-lg leading-relaxed m-4 text-gray-600"
                data-aos="zoom-out-up"
                data-aos-duration="1500"
              >
              The driving force behind our success, their dedication and expertise fuel every project we undertake.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="../assets/img/team-1-800x800.jpg"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px"
                  data-aos="fade-down"
                  data-aos-duration="1500"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Ayyaz Mehmood</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    Data Scientist
                  </p>
                  <div class="mt-6">
                    <button
                      class="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-twitter"></i></button
                    ><button
                      class="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-facebook-f"></i></button
                    ><button
                      class="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-github"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="../assets/img/team-3-800x800.jpg"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Zainab Bibi</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    Data Scientist
                  </p>
                  <div class="mt-6">
                    <button
                      class="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-google"></i></button
                    ><button
                      class="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-facebook-f"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="../assets/img/team-3-800x800.jpg"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px"
                  data-aos="fade-down"
                  data-aos-duration="1500"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Hareera Mushtaq</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    Web Developer
                  </p>
                  <div class="mt-6">
                    <button
                      class="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-google"></i></button
                    ><button
                      class="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-twitter"></i></button
                    ><button
                      class="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-instagram"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="../assets/img/team-1-800x800.jpg"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Ayaz Mehmood</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    Founder and CEO
                  </p>
                  <div class="mt-6">
                    <button
                      class="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-google"></i></button
                    ><button
                      class="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-twitter"></i></button
                    ><button
                      class="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-instagram"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-20 relative block bg-gray-900">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style="height: 80px"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-gray-900 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4 lg:pt-24 lg:pb-64">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <h2
                class="text-4xl font-semibold text-white"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                Build something
              </h2>
              <p
                class="text-lg leading-relaxed mt-4 mb-4 text-gray-500"
                data-aos="zoom-out-up"
                data-aos-duration="1500"
              >
              Let's transform your vision into reality with our expertise and passion for innovation."
              </p>
            </div>
          </div>
          <div class="flex flex-wrap mt-12 justify-center">
            <div
              class="w-full lg:w-3/12 px-4 text-center"
              data-aos="flip-left"
              data-aos-duration="1500"
            >
              <div
                class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-medal text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white">
                Innovative solutions
              </h6>
              <p class="mt-2 mb-4 text-gray-500">
                Innovative solutions crafted with expertise in AI, machine learning, web development, and app development.
              </p>
            </div>
            <div
              class="w-full lg:w-3/12 px-4 text-center"
              data-aos="flip-left"
              data-aos-duration="1500"
            >
              <div
                class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-poll text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                Cutting-edge technology
              </h5>
              <p class="mt-2 mb-4 text-gray-500">
                Cutting-edge technology tailored to your needs with precision and reliability.
              </p>
            </div>
            <div
              class="w-full lg:w-3/12 px-4 text-center"
              data-aos="flip-left"
              data-aos-duration="1500"
            >
              <div
                class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">Reliability</h5>
              <p class="mt-2 mb-4 text-gray-500">
                Clients trust us for our reliable services, knowing that we deliver on time, within budget, and to the highest quality standards.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="contactUs" class="relative block py-24 lg:pt-0 bg-gray-900">
        <div class="container mx-auto px-4">
          <div
            class="flex flex-wrap justify-center lg:-mt-64 -mt-48"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            <div class="w-full lg:w-6/12 px-4">
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300"
              >
                <div class="flex-auto p-5 lg:p-10">
                  <h4 class="text-2xl font-semibold">Want to work with us?</h4>
                  <p class="leading-relaxed mt-1 mb-4 text-gray-600">
                    Complete this form and we will get back to you in 24 hours.
                  </p>
                  <form @submit.prevent="sendEmail">
                    <div class="relative w-full mb-3 mt-8">
                      <label
                        class="block uppercase text-gray-700 text-xs font-bold mb-2"
                        for="username"
                        >Full Name</label
                      ><input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Full Name"
                        style="transition: all 0.15s ease 0s"
                        v-model="form.username"
                      />
                    </div>
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-gray-700 text-xs font-bold mb-2"
                        for="email"
                        >Email</label
                      ><input
                        type="email"
                        class="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Email"
                        style="transition: all 0.15s ease 0s"
                        v-model="form.email"
                      />
                    </div>
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-gray-700 text-xs font-bold mb-2"
                        for="service"
                        >Services</label
                      >
                      <select
                        class="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full" v-model="form.service" 
                      >
                        <option value="" selected disabled>
                          Select a service
                        </option>
                        <option value="Machine Learning & AI">
                          Machine Learning & AI
                        </option>
                        <option value="Web Development">Web Development</option>
                        <option value="App Development">App Development</option>
                      </select>
                    </div>
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-gray-700 text-xs font-bold mb-2"
                        for="message"
                        >Message</label
                      ><textarea
                        rows="4"
                        cols="80"
                        class="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Type a message..."
                        v-model="form.message"
                      ></textarea>
                    </div>
                    <div class="text-center mt-6">
                      <button
                        class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="submit"
                        style="transition: all 0.15s ease 0s"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component></footer-component>
  </div>
</template>
<script>
import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";
import emailjs from 'emailjs-com';
export default {
data() {
    return {
      backgroundImageUrl: require('@/assets/img/office.png'),
      form: {
        username: '',
        email: '',
        service: '',
        message: ''
      },
      statusMessage: ''
    };
  },
  name: "landing-page",
  components: {
    NavbarComponent,
    FooterComponent,
  },
  methods: {
    sendEmail() {
      const serviceID = process.env.VUE_APP_EMAIL_SERVICE_ID;
      const templateID = process.env.VUE_APP_EMAIL_TEMPLATE_ID;
      const userID = process.env.VUE_APP_EMAIL_USER_ID;

      alert(this.form.username);

      emailjs.send(serviceID, templateID, this.form, userID)
        .then(response => {
          console.log('SUCCESS!', response.status, response.text);
          this.statusMessage = 'Email sent successfully!';
        })
        .catch(err => {
          console.error('FAILED...', err);
          this.statusMessage = 'Failed to send email.';
        });
    }
  },

  // mounted() {
  //   // Refresh AOS to ensure animations work on dynamically added elements
  //   this.$nextTick(() => {
  //     AOS.refresh();
  //   });
  // },
  created(){
    console.log(process.env.VUE_APP_EMAIL_SERVICE_ID);
    console.log(process.env.VUE_APP_EMAIL_TEMPLATE_ID);
    console.log(process.env.VUE_APP_EMAIL_USER_ID);
  }
};
</script>
<style>
#heeruid{
    background-image: url('../assets/img/office.png');
    background-repeat: no-repeat;
    background-size: cover;
}
</style>